<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field outlined flat dense v-model="form.vendorName" disabled></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuInvoiceDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice From <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-radio-group v-model="form.type" row disabled>
                  <v-radio label="PO" value="PO"></v-radio>
                  <v-radio label="DO" value="DO"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceNumber"
                  :rules="[v => !!v || 'Invoice Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PO Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  filled
                  v-model="form.purchaseOrderNumber"
                  disabled
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="9" lg="9" v-if="form.type === 'DO'">
            <v-row>
              <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                <v-subheader>DO Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="10" lg="10" class="py-0">
                <v-text-field outlined flat dense filled v-model="form.deliveryOrder" disabled>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Received Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceReceivedDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceReceivedDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceReceivedDate"
                    @input="menuInvoiceReceivedDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Percent Invoice <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentageInvoice"
                  suffix="%"
                  @change="onInputInvoice"
                  :rules="percentageInvoiceRules"
                  :disabled="form.type === 'DO'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Percent PPN<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentagePpn"
                  suffix="%"
                  :rules="[v => v >= 0 || 'PPN must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Term <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.invoiceDuration"
                  suffix="days"
                  @change="onInputInvoiceDuration"
                  :rules="[v => v >= 0 || 'Term must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Due Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDueDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDueDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDueDate"
                    @input="menuInvoiceDueDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.chartOfAccountId"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col> -->
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.percentagePpn > 0">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxInvoiceSerialNumber"
                  :rules="[v => !!v || 'Tax Serial Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuTaxDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.taxDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.taxDate"
                    @input="menuTaxDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>DPP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.totalDpp"
                  type="number"
                  persistent-hint
                  :hint="formatPrice(form.totalDpp)"
                  :rules="[v => v >= 0 || 'DPP must more than equal 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.products"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickProduct"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.products.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <!-- <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Due Date</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.invoiceDueDate }}</span>
                    </v-col> -->
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Already DP</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.percentageDownPayment }}%</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Currency</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.currency }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Percent Up To This Invoice</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.percentageUpTo }}%</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Bill To</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.billTo }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>FOB</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.fob }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Address</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.address }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total PO</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalPoAfterDiscountAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Inv</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Discount</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.discountAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" v-if="form.percentagePpn > 0">
            <v-card outlined>
              <v-card-title class="py-0">Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.taxAmount"
                  @change="onInputTax"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Disc</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterDisc) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterTax) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-product
      :dialog="dialog"
      :item="item"
      :index="index"
      type="Invoice"
      :accounts="accounts"
      @save="save"
      @deleteItem="deleteItem"
    ></dialog-product>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="update"
      @close="close"
      :currency="form.currency"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import DialogProduct from "@/components/DialogProduct";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "purchase-invoice-edit",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-product": DialogProduct,
    DialogReviewJournal,
  },
  props: {
    form: Object,
    accounts: Array,
  },
  data() {
    return {
      menuInvoiceDate: false,
      menuInvoiceDueDate: false,
      menuInvoiceReceivedDate: false,
      menuTaxDate: false,
      item: {},
      index: -1,
      dialog: false,
      dialogReview: false,
      valid: true,
      journals: [],
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "Product Detail",
          value: "productDetail",
          sortable: false,
          divider: true,
        },
        {
          text: "Qty",
          value: "qty",
          sortable: false,
          divider: true,
        },
        {
          text: "U/M",
          value: "unitMeasure",
          sortable: false,
          divider: true,
        },
        {
          text: "U/Price",
          value: "unitPrice",
          sortable: false,
          divider: true,
        },
        {
          text: "Disc %",
          value: "discount",
          sortable: false,
          divider: true,
        },
        {
          text: "Amount",
          value: "total",
          sortable: false,
          divider: true,
        },
        {
          text: "PO#",
          value: "poNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "DO#",
          value: "doNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Project",
          value: "project",
          sortable: false,
          divider: true,
        },
        {
          text: "Dept",
          value: "department",
          sortable: false,
          divider: true,
        },
        {
          text: "Reff",
          value: "reference",
          sortable: false,
          divider: true,
        },
        {
          text: "Account",
          value: "accountName",
          sortable: false,
          divider: true,
        },
      ],
    };
  },
  computed: {
    percentageInvoiceRules() {
      const total = this.form.percentageDownPayment;
      return [
        v => !!v || "Percentage invoice is required",
        v =>
          (v <= 100 - total && v >= 1) ||
          `$Percentage invoice must be more than 1 and less than ${100 - total}`,
      ];
    },
  },

  methods: {
    checkJournal() {
      if (this.$refs.form.validate() && this.form.products.length > 0) {
        this.journals = [
          {
            accountName: `PAJAK MASUKAN`,
            debit: this.form.taxAmount,
            credit: 0,
          },
          {
            accountName: `UANG MUKA PEMBELIAN ${this.form.currency}`,
            debit: 0,
            credit: this.form.totalDownPayment,
          },
          {
            accountName: `${this.form.vendorName} ${this.form.currency}`,
            debit: 0,
            credit: this.form.totalAmountAfterTax,
          },
        ];
        let map = this.form.products.map(x => x.accountName);
        let array = map.filter((v, i, a) => a.indexOf(v) === i);
        array.forEach(y => {
          let total = 0;
          this.form.products.forEach(x => {
            if (x.accountName === y) {
              if (this.form.type === "PO") {
                total += x.total * (this.form.percentageInvoice / 100);
              } else {
                total += x.total;
              }
            }
          });
          this.journals.unshift({
            accountName: `${y}`,
            debit: parseFloat(total.toFixed(2)),
            credit: 0,
          });
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    update() {
      if (this.$refs.form.validate() && this.form.products.length > 0) {
        if (this.isJournalBalance()) {
          this.$store.commit("SET_LOADER", true);
          this.form.totalDownPayment =
            (this.form.percentageDownPayment / 100) * (this.totalAmount - this.discountAmount);
          this.$store
            .dispatch("purchaseInvoice/update", this.form)
            .then(() => this.$store.commit("SET_LOADER", false))
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", true);
            });
        } else {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Journal not balance",
          });
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    isJournalBalance() {
      let totalDebit = this.form.totalAmount - this.form.discountAmount + this.form.taxAmount;
      let totalCredit = this.form.totalDownPayment + this.form.totalAmountAfterTax;
      return parseFloat(totalDebit.toFixed(2)) - parseFloat(totalCredit.toFixed(2)) === 0;
    },
    invoiceDueDate() {
      let dueDate = moment(this.form.invoiceReceivedDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
      return dueDate;
    },
    onInputInvoiceDuration() {
      this.form.invoiceDueDate = this.invoiceDueDate();
    },
    onInputPercentagePpn() {
      this.form.taxAmount = this.totalAmount * (this.form.percentagePpn / 100);
    },
    onInputInvoice() {
      this.form.totalAmount = this.form.totalPoAmount * (this.form.percentageInvoice / 100);
      this.form.discountAmount =
        this.form.totalPoDiscountAmount * (this.form.percentageInvoice / 100);
      this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
      this.form.taxAmount = this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100);
      this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
      this.form.percentageUpTo = this.form.percentageDownPayment + this.form.percentageInvoice;
    },
    onInputPpn() {
      this.form.taxAmount = this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100);
      this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
    },
    onInputTax() {
      this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
      this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
      this.form.totalAmountAfterTaxIDR = this.form.totalAmountAfterDiscIDR + this.form.taxAmountIDR;
    },
    deleteItem(index) {
      if (index > -1) {
        this.form.products.splice(index, 1);
        this.dialog = false;
      }
    },
    save(item) {
      this.form.products[this.index] = item;
    },
    close() {
      this.dialog = this.dialogReview = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickProduct(item) {
      this.item = item;
      this.index = this.form.products.map(x => x).indexOf(item);
      this.dialog = true;
    },
  },
};
</script>

<style></style>
