<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="purchaseInvoice"
          ref="formEdit"
          :accounts="accounts"
          :vendors="vendors"
          :purchaseOrders="purchaseOrders"
          :deliveryOrders="deliveryOrders"
          v-if="!edit"
        ></form-edit>
        <detail
          :id="id"
          :accounts="accounts"
          :item="purchaseInvoice"
          :purchaseOrders="purchaseOrders"
          :deliveryOrders="deliveryOrders"
          v-if="edit"
        ></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="printJournal">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Journal</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="createJournalManual">
                  <v-icon>
                    mdi-notebook
                  </v-icon>
                </v-btn>
              </template>
              <span>Create Journal Manual</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && purchaseInvoice.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="openUpdateTaxDate">
                  <v-icon>
                    mdi-update
                  </v-icon>
                </v-btn>
              </template>
              <span>Update Tax Date</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && purchaseInvoice.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="dialogDelete">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="purchaseInvoice.invoiceNumber"
      title="Purchase Invoice"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
    <dialog-update-tax-date
      :dialog="dialogTax"
      :form="purchaseInvoice"
      @save="updateTaxDate"
      @close="close"
    ></dialog-update-tax-date>
  </form>
</template>

<script>
import Detail from "./DetailPurchaseInvoice";
import FormEdit from "./FormPurchaseInvoice";
import DialogDelete from "@/components/DialogDelete";
import { mapState } from "vuex";
import DialogUpdateTaxDate from "@/components/DialogUpdateTaxDate.vue";

export default {
  name: "purchase-invoice-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
    DialogUpdateTaxDate,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    dialogTax: false,
    purchaseOrders: [],
    deliveryOrders: [],
    purchaseInvoice: {},
    accounts: [],
  }),
  computed: {
    ...mapState("external", ["vendors"]),
  },
  methods: {
    async getPurchaseInvoice() {
      await this.$store
        .dispatch("purchaseInvoice/getById", this.id)
        .then(response => (this.purchaseInvoice = response.data));
    },
    async getPurchaseOrders() {
      const response = await this.$store.dispatch("external/getPurchaseOrdersById", {
        id: this.id,
        type: "Invoice",
      });
      if (response.status === 200) {
        this.purchaseOrders = response.data;
      }
    },
    async getDeliveryOrders() {
      const response = await this.$store.dispatch("external/getDeliveryOrdersById", {
        id: this.id,
        type: "Invoice",
      });
      if (response.status === 200) {
        this.deliveryOrders = response.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    printJournal() {
      this.$router.push({ name: "Journal Purchase Invoice", params: { id: this.id } });
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.checkJournal();
    },
    dialogDelete() {
      this.dialog = true;
    },
    deleteItem(id) {
      this.$store.dispatch("purchaseInvoice/delete", id);
    },
    close() {
      this.dialog = this.dialogTax = false;
    },
    cancel() {
      this.getPurchaseInvoice();
      this.edit = true;
    },
    openUpdateTaxDate() {
      this.dialogTax = true;
    },
    async updateTaxDate(item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchaseInvoice/updateTaxDate", item)
        .then(() => {
          this.$store.commit("SET_LOADER", false);
          window.location.reload();
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    async createJournalManual() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchaseInvoice/createJournalManual", this.id)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getAccounts(),
        this.getPurchaseOrders(),
        this.getDeliveryOrders(),
        this.$store.dispatch("external/getVendors"),
        this.getPurchaseInvoice(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getPurchaseInvoice();
  },
};
</script>

<style></style>
